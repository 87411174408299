import PinInput from "react-pin-input";
import '../styles/_Home.scss';

function Home({ setLockMethod, lockStatus }) {
  const verifyPassword = (value) => {
    if ('2018-10-10' === value) {
      setLockMethod(false);
    }
  }

  const imgSrc = lockStatus ? "/images/home_locked.png" : "/images/home.png";

  return (
    <div className="homeContainer">
      <div className="picContainer">
        <img src={imgSrc} alt="Welcome Bitmoji"></img>
      </div>
      {
        lockStatus ?
          <div className="pinField">

            <p>
              Introduce la fecha en que todo comenzó para desbloquear
            </p>
            <p className="pinPlaceholder">
              El pin debe tener el formato (yyyy-mm-dd)
            </p>
            <PinInput
              length={10}
              type="custom"
              onComplete={(value, index) => verifyPassword(value)}
              regexCriteria={/^[0-9-]*$/}
            />
          </div>
          :
          <p className="unlockedApp">
            🥰😍💌 Bienvenida a Taralandia
          </p>
      }
    </div>
  );
}

export default Home;