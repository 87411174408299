import '../styles/_History.scss';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import history from './history.json';
import { useState, useEffect } from 'react';

function History() {
  const [ contentWidth, setContentWidth ] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    let width = document.getElementsByClassName('content')[0].clientWidth;
    setContentWidth(width)
    let height = document.getElementsByClassName('content')[0].clientHeight;
    setContentHeight(height*0.8)
}, [])

  return (
    <div className="historyContainer" style={{"maxHeight":contentHeight}}>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        infiniteLoop={true}
        autoPlay={1000}
        width={contentHeight*1.72 < contentWidth ? contentHeight*1.72 : contentWidth }
      >
        {
          Object.keys(history).map((key) => {
            return <div>
              <img src={history[key]['img']} alt='history'></img>
              <p className="label">{history[key]['label']} <a href={history[key]['map']}>[👉🏻🗺️]</a></p>
            </div>
          })
        }
      </Carousel>
    </div>
  );
}

export default History;