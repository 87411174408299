import { forwardRef, useState, useEffect } from 'react';
import '../styles/_Book.scss';
import HTMLFlipBook from 'react-pageflip';
import recipes from './recipes.json';

const Page = forwardRef((props, ref) => {
    return (
        <div className="demoPage" ref={ref}>
            {
                props.backgroundImg ?
                    <div>
                        <img src={props.backgroundImg} className="coverImg" alt="cover"></img>
                    </div>
                    :
                    props.coverImg ?
                        <div className='pageContent'>
                            <div className='spacer1rem'></div>
                            <img src={props.coverImg} className="recipeImg" alt="Book Page Cover"></img>
                            {
                                props.hasSpacer ?
                                <img src="/images/book/dividing.jpg" className="spacer" alt="Divider"></img>
                                :
                                <div></div>
                            }
                            <p className='bookContent'>{props.children}</p>
                        </div>
                        :
                        <div className='pageContent'>
                            <p className='bookContent'>{props.children}</p>
                        </div>
            }
        </div>
    );
});

function Book() {
    const [ contentWidth, setContentWidth ] = useState(0);
    const [ contentHeight, setContentHeight ] = useState(0);
  
    useEffect(() => {
      let width = document.getElementsByClassName('content')[0].clientWidth;
      setContentWidth(width*0.8)
      let height = document.getElementsByClassName('content')[0].clientHeight;
      setContentHeight(height*0.8)
  }, [])

    return (
        <div className="bookContainer">
            <HTMLFlipBook
                width={contentWidth < 300 ? contentWidth : 300}
                height={contentWidth < 300 ? contentWidth*1.6 : contentHeight}
                showCover={true}
                drawShadow={false}
            >
                <Page
                    backgroundImg={'/images/book/cover.png'}
                >
                    Recetario del team tara
                </Page>
                {
                    Object.keys(recipes).map( (key ) => {
                        let imgSrc = `/images/book/page${key}.jpg`;
                        return <Page
                            coverImg={imgSrc}
                            hasSpacer={true}
                        >{recipes[key]}</Page>
                    })
                }
                <Page
                    coverImg={'/images/book/back.png'}
                    hasSpacer={false}
                >
                    Seguiré añadiendo nuestras recetas por acá 😉
                </Page>
            </HTMLFlipBook>
        </div>
    );
}

export default Book;