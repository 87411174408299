import { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from './components/Home';
import Book from './components/Book';
import History from './components/History';
import './styles/_App.scss';

function App() {
  const [isLocked, setIsLocked] = useState(true);

  return (
    <Router>
      <div className="appContainer">
        <nav className="card navMenu">
          { isLocked
            ?
            <ul className="navMenu__container">
              <li className="navMenu__container__link">
                <Link to="/">🍬🏠</Link>
              </li>
            </ul>
            :
            <ul className="navMenu__container">
              <li className="navMenu__container__link">
                <Link to="/">🍬🏠</Link>
              </li>
              <li className="navMenu__container__link">
                <Link to="/book">👩🏻‍🍳🧑🏻‍🍳</Link>
              </li>
              <li className="navMenu__container__link">
                <Link to="/history">📑🗺️</Link>
              </li>
            </ul>
          }
        </nav>
        <main className="card content">
            { isLocked
              ?
              <Routes>
                  <Route path="/" element={<Home setLockMethod={setIsLocked} lockStatus={isLocked} />} />
              </Routes>
              :
              <Routes>
                  <Route path="/book" element={<Book />} />
                  <Route path="/history" element={<History />} />
                  <Route path="/" element={<Home setLockMethod={setIsLocked} lockStatus={isLocked} />} />
              </Routes>
            }
            <p>Hecho con 💝 por <a href="https://github.com/alexiglesias31">🍬</a></p>
        </main>
      </div>
    </Router>
  );
}

export default App;
